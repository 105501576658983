.containerArticle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.containerGoBackArticle {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.iconGoBackArticle {
    width: 0.5vw;
    height: 0.85vw;
}

.goBackArticle {
    margin-left: 0.6vw;
    font-weight: 400;
    font-size: 0.89vw;
    letter-spacing: 0.75px;
    text-decoration-line: underline;
    color: #0F1841;
}

.containerTitleArticle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.titleArticle {
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerButtonArticle {
    width: 12vw;
}

.descriptionArticle {
    font-weight: 400;
    font-size: 0.75vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.pdfArticle {
    margin-top: 2.05vw;
}
