.containerSession {
    display: flex;
    flex-direction: row;
    width: 95%;
    padding: 10px 20px;
    margin: 10px 5px;
    border-radius: 30px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.containerTextSession{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.nameSession {
    font-weight: 700;
    font-size: 1.10vw;
    letter-spacing: 1px;
    color: #000000;
    margin-top: 0.594vw;
    margin-bottom: 0.4vw;
}

.dateSession {
    text-align: left;
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}