.containerNavBar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.logoNavBar {
    width: 11.5vw;
    margin-top: 45px;
}

.containerItemsNavBar {
    width: 90%;
}
