.containerSessions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}


.containerColumns {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.column{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 100% !important;
}

.containerItemsSession {
    overflow: scroll;
    width: 100%;
    height: 50vh;
    max-height: 50vh;
    margin-top: 3vw;
}

.titleColumn {
    margin-right: 2vw;
    font-weight: 700;
    font-size: 1.1vw;
    letter-spacing: 1px;
    color: #000000;
}