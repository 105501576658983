.containerHistory {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    height: 100%;
    max-height: 100%;
}

.titleHistory {
    font-weight: 700;
    font-size: 1.3vw;
    letter-spacing: 1px;
    color: #000000;
}

.containerItemsHistory {
    overflow: scroll;
    width: 100%;
    max-height: 700px;
    margin-top: 3vw;
}
