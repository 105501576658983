.containerSpeciality {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2vw;
    margin-right: 1.25vw;
    margin-bottom: 10px;
    padding: 0 0.65vw;
    border-radius: 20px;
    font-weight: 400;
    font-size: 0.75vw;
    letter-spacing: 0.75px;
    color: #595959;
    background: #F4F4F4;
}
