.containerCompleteArticleComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 99%;
}

.containerArticleComponent {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2.5vw;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.dateArticleComponent {
    margin-top: 0.45vw;
    margin-left: auto;
    font-weight: 400;
    font-size: 0.75vw;
    letter-spacing: 0.75px;
    color: #969696;
}

.containerDetailsArticleComponent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0.95vw 1.825vw 0.95vw 1.35vw;
}

.detailsArticleComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.detailsDateArticleComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 4vw;
    margin-left: auto;
}

.titleArticleComponent {
    font-weight: 700;
    font-size: 1.25vw;
    letter-spacing: 1px;
    color: #000000;
    margin: 0;
}

.descriptionArticleComponent {
    font-weight: 400;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
    margin: 0.5vw 0 0 0;
}

.iconArticleComponent {
    width: 1.1vw;
    height: 0.8vw;
}

.containerSubarticlesArticlesComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 98%;
    margin-top: 0.65vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    cursor: pointer;
}

.subarticleArticleComponent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
}

.lastSubarticleArticleComponent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.subarticleTextArticleComponent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 1.1vw;
}

.titleSubarticleArticleComponent {
    margin: 0.287vw 0 0.578vw 0;
    font-weight: 600;
    font-size: 1vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.subtitleSubarticleArticleComponent {
    margin: 0 0 0.6vw 0;
    font-weight: 400;
    font-size: 0.75vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.iconSubarticleArticleComponent {
    margin-right: 1.40vw;
}
