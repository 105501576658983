.containerMenuChat {
    position: absolute;
    width: 80%;
    height: 100%;
    background-color: transparent;
}

.menuChat {
    position: absolute;
    top: 4.2vw;
    right: 2.3vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 17vw;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
}

.itemMenuChat {
    width: 100%;
    cursor: pointer;
}

.textItemMenu {
    margin: 1.1vw 2.3vw;
    font-weight: 400;
    font-size: 0.88vw;
    letter-spacing: 0.75px;
    color: #000000;
}

.border {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.halfBorder {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
